/**
 * border radius
 */

.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px;
}

/* cart badge */
.footer-icon-social {
  bottom: 10px;
  position: relative;
}

.cart-badge,
.cart-badge:hover {
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  font-style: italic;
  background: #000;
}
/**
  * single product page - product name
  */

.name {
  background: #212529;
  height: 40px;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
}

/* black shade form 10-1 */
.black-10 {
  background: #f2f2f2;
}
.black-9 {
  background: #e6e6e6;
}
.black-8 {
  background: #d9d9d9;
}
.black-7 {
  background: #cccccc;
}
.black-6 {
  background: #bfbfbf;
}
.black-5 {
  background: #b3b3b3;
}

/**
  * product image on card
  */

.product-img {
  width: 100%;

  overflow: hidden;
}

.jumbotron h2 {
  margin-top: -20px;
}

@media only screen and (max-width: 600px) {
  .jumbotron h2 {
    margin-top: 10px;
  }
}
html {
  font-size: 18px;
}

@media (min-width: 576px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

/**
  * jumbotron animation
  */

.jumbotron {
  /* height: 150px; */
  color: #fff;
  background: linear-gradient(-45deg, #000, #353535, #0f0f0f, #f50000);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.splash-screen {
  background-color: #212529;
  width: 100%;
  height: 100vh;
}
