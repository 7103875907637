body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
.productItem {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
}

.dropdown-toggle::after {
  display: none !important;
}

body {
  position: relative;
  min-height: 100vh;
  height: fit-content;
}

@media (max-width: 944px) {
  .productItem {
    flex-direction: column;
    justify-content: center;
  }
}
.productItemImages {
  flex: 1;
}
.productItemContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 2;
}

.zoom-wrapper {
  width: 100%;
  /* height: 500px; */
  overflow: hidden;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 12px;
}

.zoom-img {
  width: 100%;
  /* transition: transform 0.7s ease; */
}

.zoom-wrapper:hover .zoom-img {
  transform: scale(3);
  cursor: crosshair;
}
.card {
  width: 20rem;
}

.view {
  background-color: #da0e1a;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
  opacity: 0;
  color: #fff;
  border: none;
  outline: none;
  width: 100%;
}
.view:hover {
  background-color: #c40d17;
}
.card:hover .view {
  transform: translateY(0%);
  opacity: 1;
}
@media (max-width: 768px) {
  .card {
    width: 15rem;
    margin: 0 auto;
  }
}

.addToCardButtons {
  transition: all 0.3s;
}

.addToCardButtons button {
  transition: all 0.3s;
}
.addToCardButtons button:hover {
  background-color: #00000013 !important;
}
@media (max-width: 768px) {
  .ctaCartContainer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
.outOfStock {
  position: absolute;
  top: 5rem;
  right: 0;
  padding: 0.1rem 1rem;
  color: #fff;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.cardsUpContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: "flex";
  margin: 0 auto;
  display: block;
  width: 74.75%;
}
@media (min-width: 1280px) {
  .cardsUpContainer {
    justify-content: center;
    display: flex;
  }
}

.stock {
  /* position: relative; */
  background-color: #ddd;
}
.stock::after {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #fff;
  left: -10px;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ff0000 transparent;
}

.cardsContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: "flex";
  height: fit-content;
}
@media (min-width: 768px) {
  .cardsUpContainer,
  .cardsContainer {
    justify-content: center;
    display: flex;
    width: 100%;
  }
}

/* CART STYLES */
.cart {
}
.cart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.cart-header h2 {
  margin: 0;
  font-size: 1.5rem;
}
.cart-summary {
  flex: 1 / 3;
}
.cart-items-container {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding: 1rem 5rem; */
}
.dropdownToggle {
  display: relative;
  /* background-color: #00b300; */
}
.dropdownToggle::after {
  content: "";
  display: none;
}
/* USER ADMIN LINKS */
@media (max-width: 768px) {
  .userAdminLinks {
    flex: 1;
    margin: 0 auto;
    width: 100%;
  }
  .cart-empty {
    flex: 1;
    width: 100%;
  }
  .cart-empty p {
    text-align: right;
  }
  .cart-items-container {
    justify-content: center;
    /* align-items: flex-start; */
    padding: 0;
    flex-wrap: wrap;
  }
  .cart-summary {
    flex: 1 !important;
    width: 100% !important;
  }
}

.shopping-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  background-color: #00b300;
  border-radius: 8px;
  padding: 0.25rem 0.75rem;
  display: block;
  text-decoration: none;
  width: fit-content;
}
.shopping-btn:hover {
  color: #fff;
}
.clear-cart-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  background-color: #ff0000;
  border-radius: 8px;
  padding: 0.25rem 1rem;
}

.cart-empty {
  text-align: center;
  padding: 40px 0;
  color: #666;
}

.cart-items {
  margin-bottom: 30px;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
  position: relative;
}

.item-image {
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 15px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-image {
  color: #999;
  font-size: 0.8rem;
  text-align: center;
}

.item-details {
  flex-grow: 1;
}

.item-details h3 {
  margin: 0 0 5px 0;
  font-size: 1rem;
}

.item-price {
  color: #666;
  margin: 0;
}

.item-quantity {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
}

.item-quantity span {
  width: 40px;
  text-align: center;
}

.item-total {
  font-weight: bold;
  width: 100px;
  text-align: right;
}

.remove-btn {
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 10px;
}

.remove-btn:hover {
  color: #ff4d4d;
}

.cart-summary {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 4px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.summary-row.total {
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #ddd;
}

.checkout-btn {
  width: 100%;
  padding: 12px;
  background-color: #4a6de5;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  margin-top: 15px;
}

.checkout-btn:hover {
  background-color: #3a5bd5;
}
